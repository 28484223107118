import React from 'react';
import Style from '../styles/pricing.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaPaw } from "react-icons/fa6";
import util from '../../util';
import photo1 from '../images/photos/1.jpg';
import photo2 from '../images/photos/2.jpg';
import ImageGallery from "react-image-gallery";

const UiPricing = ({
  styles
}) => {

  const images = [
    {
      original: photo1
    },
    {
      original: photo2
    }
  ];

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Наш уютный уголок</h1>
        <div className={Style.content}>
            <div className={Style.image}> 
              <ImageGallery 
                items={images} 
                size="cover"
                /> 
              </div>
              <div className={Style.prices}>
                  <div className={Style.price}>
                      <div className={Style.animal}>
                      Для собак
                      </div>
                      от 1000 ₽/сутки
                  </div>
                  <div className={Style.price}>
                      <div className={Style.animal}>
                      Для котов
                      </div>
                      от 500 ₽/сутки
                  </div>
              </div>
        </div>
        <button className={Style.button} onClick={() => window.dispatchEvent(new Event("openModal"))}>Забронировать <FaPaw /></button>
      </div>
    </section>
  );
};

export default UiPricing; 
