import Style from "./assets/styles/app.module.scss"
import React from 'react';
import UiHeader from "./assets/components/header";
import MainPage from "./assets/pages/main";
import UiAbout from "./assets/components/about";
import UiNeedo from "./assets/components/needo";
import UiRules from "./assets/components/rules";
import UiPricing from "./assets/components/pricing";
import UiMap from "./assets/components/map";
import UiFooter from "./assets/components/footer";
import UiChoose from "./assets/components/choose";
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import pages from "./pages"
import UiService from "./assets/components/service";
import UiFAQ from "./assets/components/FAQ";
import ClinicPage from "./assets/pages/clinic";
import UiQuestions from "./assets/components/questions";
import Modal from 'react-modal';
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import AboutPage from "./assets/pages/about";
import CostHostelPage from "./assets/pages/cost";
import VisitPage from "./assets/pages/visit";
import OperationPage from "./assets/pages/operation";
import HospitalPage from "./assets/pages/hospital";
import PricesPage from "./assets/pages/prices";
import UiClinic from "./assets/components/clinic";


function App() {
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = React.useState(false);


  window.addEventListener('openModal', () => { 
    openModal();
  });


  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden"
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "auto"

  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10); 

    document.documentElement.style.setProperty('--accent', pages[location["pathname"]]["color"]);

  }, [location]);

  return (
    <div className={Style.app}>
      <UiHeader
        location={location}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={Style.modal}
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <span>Связь со мной</span>
        <div className={Style.me}>
          <a href='https://t.me/dailapu_ufa'><FaTelegram />t.me/dailapu_ufa</a>
          <a href='https://wa.me/79871380121'><FaWhatsapp />+7 (987) 138-01-21</a>
          <a href='tel:+79871380121'><FaPhone />+7 (987) 138-01-21</a>
        </div>
      </Modal>
      <Routes>
        <Route path="/" index element={
          <>
            <UiChoose />
            <UiAbout
              styles={["primary"]}
            />
          </>
        }/>
        <Route path="/hostel" element={
          <>
            <MainPage 
              second={false}
            />
            <UiNeedo
              styles={["secondary" , "green"]}
            />
            <UiRules
              styles={["primary"]}
            />
            <UiPricing
              styles={["secondary" , "green"]}
            />
          </>
        }/>
        <Route path="/clinic" element={
          <>
            <ClinicPage 
              second={false}
            />
            <UiService 
              styles={["primary" , "green"]}
            />
            <UiFAQ 
              styles={["primary"]}
            />
            <UiClinic
              styles={["secondary" , "green"]}
            />
          </>
        }/>
        <Route path="/about" element={<AboutPage styles={["primary","green"]}/>}/>
        <Route path="/costhostel" element={<CostHostelPage styles={["primary","green"]}/>}/>
        <Route path="/visit" element={<VisitPage styles={["primary","green"]}/>}/>
        <Route path="/operation" element={<OperationPage styles={["primary","green"]}/>}/>
        <Route path="/hospital" element={<HospitalPage styles={["primary","green"]}/>}/>
        <Route path="/price" element={<PricesPage styles={["primary","green"]}/>}/>
      </Routes>
      <UiMap
        second={false}
      />
      <UiQuestions 
        styles={["primary","green"]}
      />
      <UiFooter
        location={location}
        />
    </div>
  );
}

export default App;
