export default {
    "/" : {
        name: "Главная страница",
        pages: [
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4EAFA9"
    },
    "/hostel" : {
        name: "Зоогостиница",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Обо мне",
                link:"/about"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#34A853"
    },
    "/clinic" : {
        name: "Ветклиника",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Обо мне",
                link:"/about"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            }
        ],
        color: "#4286F5"
    },
    "/about" : {
        name: "Обо мне",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4EAFA9"
    },
    "/costhostel" : {
        name: "Заселение",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#34A853"
    },
    "/visit" : {
        name: "Заселение",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4286F5"
    },
    "/operation" : {
        name: "Операция",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4286F5"
    },
    "/hospital" : {
        name: "Стационар",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4286F5"
    },
    "/price" : {
        name: "Услуги и цены",
        pages: [
            {
                title:"Главная",
                link:"/"
            },
            {
                title:"Зоогостиница",
                link:"/hostel"
            },
            {
                title:"Ветклиника",
                link:"/clinic"
            }
        ],
        color: "#4286F5"
    }
}