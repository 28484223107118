import React from 'react';
import Style from '../styles/clinic.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaPaw } from "react-icons/fa6";
import util from '../../util';

import photo3 from '../images/photos/3.jpg';
import photo4 from '../images/photos/4.jpg';
import photo5 from '../images/photos/5.jpg';
import photo6 from '../images/photos/6.jpg';

import ImageGallery from "react-image-gallery";

const UiClinic = ({
  styles
}) => {

  const images = [
    {
      original: photo3
    },
    {
      original: photo4
    },
    {
      original: photo5
    },
    {
      original: photo6
    }
  ];

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Уголок здоровья</h1>
        <div className={Style.content}>
            <div className={Style.image}> 
              <ImageGallery 
                items={images} 
                
                /> 
              </div>
        </div>
      </div>
    </section>
  );
};

export default UiClinic; 
