import React from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';

const AboutPage = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/"}><FaLongArrowAltLeft /></Link>Обо мне</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.head}>
                        Всем привет!👋</div>
                    <div className={Style.body}>
                        Меня зовут Алина Джадд, я ветеринарный врач и зоопсихолог. Я также большой любитель животных и просто не представляю свою жизнь без них. Я начинала свой путь с волонтерства, помогала бездомным животным и в какой-то момент поняла, что мне не хватает знаний и начала учиться. Я закончила Башкирский государственный аграрный университет. За время учебы и практики в ветклиниках я поняла, что ветеринария очень тесно связана с зоопсихологией и поэтому я прошла курс "Кабинет зоопсихолога" Софьи Баскиной. Также я закончила базовый курс по кинологии и посетила несколько конференций по поведению животных и поведенческой медицине. Все эти знания помогают мне в работе. Моя главная цель - благополучие вашего питомца и распространение достоверной информации о правильном уходе и воспитании животных.
                    </div>
                
            </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage; 
