import React, { useEffect, useState } from 'react';
import Style from '../styles/service.module.scss'
import SectionStyle from '../styles/section.module.scss'
import util from '../../util';
import { FaUserDoctor } from "react-icons/fa6";
import { BiSolidInjection } from "react-icons/bi";
import { TbMedicalCrossFilled } from "react-icons/tb";
import { FaHospital } from "react-icons/fa6";

const UiService = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Услуги клиники</h1>
        <div className={Style.content}>
          <div className={Style.item}><div className={Style.icon}><FaUserDoctor/></div>Терапия</div>
          <div className={Style.item}><div className={Style.icon}><BiSolidInjection /></div>Вакцинация</div>
          <div className={Style.item}><div className={Style.icon}><TbMedicalCrossFilled /></div>Хирургия</div>
          <div className={Style.item}><div className={Style.icon}><FaHospital /></div>Стационар</div>
        </div>
        
      </div>
    </section>
  );
};

export default UiService; 
