import React, { useEffect, useState } from 'react';
import Style from '../styles/question.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { IoSearchOutline } from "react-icons/io5";
import util from '../../util';

const UiQuestions = ({
  styles
}) => {

  return (
    <section className={[Style.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={Style.wrapper}>
        <h3 className={Style.title}>Остались вопросы?</h3>
        <button className={Style.button} onClick={() => window.dispatchEvent(new Event("openModal"))}>
          Узнать ответы <IoSearchOutline />
        </button>
      </div>
    </section>
  );
};

export default UiQuestions; 
