import React from 'react';
import Style from '../styles/faq.module.scss'
import SectionStyle from '../styles/section.module.scss'
import util from '../../util';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";

const UiFAQ = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Уголок полезных знаний</h1>
        <ul className={Style.content}>
          <Link to={"/visit"}><span>Подготовка к визиту</span><FaLongArrowAltRight /></Link>
          <Link to={"/operation"}><span>Подготовка к плановой операции</span><FaLongArrowAltRight /></Link>
          <Link to={"/hospital"}><span>Стационар</span><FaLongArrowAltRight /></Link>
          <Link to={"/price"}><span>Услуги и цены</span><FaLongArrowAltRight /></Link>
        </ul>
      </div>
    </section>
  );
};

export default UiFAQ; 
