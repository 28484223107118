import React from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';

const HospitalPage = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/clinic"}><FaLongArrowAltLeft /></Link>Стационар</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.body}>
                      В стационаре возможно размещение как маленьких пациентов, так и очень больших. Кошки и собаки мелких пород содержатся в клетках, крупные собаки - в вольерах. Каждому питомцу предоставляется: плед, пеленка, миски для еды и воды, для кошек - лоток с наполнителем. <br />
                      Ежедневно персонал клиники проводит уборку клетки (вольера) при помощи дезинфицирующих средств, происходит замена пеленок, пледов, мисок.<br />
                      Корм предоставляется владельцем.<br />
                      У нас в стационаре можно разместить животное с листом назначений из другой клиники. В данном случае мы не несем ответственность за лечение и не консультируем, мы просто выполняем манипуляции, назначенные вашим лечащим врачом. <br />
                      Также мы можем свозить вашего питомца на прием к специалисту в ту клинику, в которой он проходит лечение, за отдельную плату.<br />
                    </div>
                
            </div>
        </div>
      </div>
    </section>
  );
};

export default HospitalPage; 
