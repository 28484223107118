import React, { useState } from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';
import { FaDog } from "react-icons/fa6";
import { FaCat } from "react-icons/fa6";

const VisitPage = ({
  styles
}) => {
	const [checked, setChecked] = useState(true);

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/clinic"}><FaLongArrowAltLeft /></Link>Подготовка к визиту</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.head}>
                      Общие рекомендации 
                    </div>
                    <div className={Style.body}>
                      <div className={Style.choose}>
                        <FaCat 
                          className={(!checked)? Style.active: ""}
                        />
                        <label class={Style.switch}>
                          <input type="checkbox"  checked={checked} onClick={() => setChecked(!checked)}/>
                          <span class={[Style.slider,Style.round].join(" ")}></span>
                        </label>
                        <FaDog 
                          className={(checked)? Style.active: ""}
                          />
                      </div>
                      {
                        (checked)? 
                        <>
                        Для собак: 
                        <br />
                        1. Используйте специальные гамаки для перевозки.<br />
                        2. Если животное укачивает, закройте заднее окно шторкой и обратитесь к ветеринару, возможно, он подберёт препараты от укачивания.<br />
                        3. Возьмите с собой намордник.<br />
                        4. Если посещение клиники вызывает у собаки страх или тревожность, дайте ей перед посещением клиники успокоительное (проконсультируйтесь с ветеринарным врачом по поводу препарата и дозировки).<br />
                        </>
                        :
                        <>
                        Для кошек:<br />
                        1. Не перевозите питомца на руках — это небезопасно.<br />
                        2. Используйте переноску.<br />
                        3. Положите на дно переноски подстилку, на которой кошка обычно лежит, или пеленку, можно за 30 минут до поездки обработать переноску феромонами.<br />
                        4. Поместите животное в переноску:<br />
                        - если питомец спокойный — положите внутрь немного лакомства;<br />
                        - если питомец беспокойный — поставьте переноску в угол, придерживайте руками лапы животного и поместите его в переноску задними лапами вперёд;<br />
                        - если питомец отказывается заходить в переноску — оберните его одеялом, поместите в переноску, накройте её покрывалом.<br />
                        5. Во время движения не ударяйте переноску о ноги.<br />
                        6. Если ваш питомец посещает клинику не в первый раз и вы знаете, что животное проявляет агрессию при манипуляциях, то дайте ему успокоительное (проконсультируйтесь с ветеринарным врачом по поводу препарата и дозировки).<br />
                        </>
                      }
                    </div>
                
            </div>
        </div>
      </div>
    </section>
  );
};

export default VisitPage; 
