import React from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';

const OperationPage = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/clinic"}><FaLongArrowAltLeft /></Link>Подготовка животного к плановой операции</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.body}>
                      1. Оптимальный возраст для кастрации кота - с 3 месяцев, для кобеля - в зависимости от породы, но не ранее 6 месяцев. Кошку необходимо стерилизовать до первой охоты, суку - после первой течки.  <br />
                      2. Питомец должен быть вакцинирован от вирусных инфекций и бешенства, так как в ветеринарной клинике повышен риск подхватить инфекцию от других животных. <br />
                      3. Питомца необходимо обрабатывать от глистов 1 раз в квартал. Если вы делаете это регулярно, то никакой дополнительной обработки перед операцией не требуется. <br />
                      3. Рекомендуется сделать ЭХО сердца, сдать общий анализ крови и биохимический анализ крови с целью проверки общего состояния здоровья вашего питомца.<br />
                      4. Анестетики вызывают у животного тошноту и рвоту и во время операции и после нее. Поэтому врачи рекомендуют в течение 8-10 часов перед вмешательством не кормить питомца. Доступ к воде следует ограничить за 2 часа перед операцией. На голодный желудок животное легче перенесет наркоз.<br />
                    </div>
                
            </div>
        </div>
      </div>
    </section>
  );
};

export default OperationPage; 
