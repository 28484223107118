import React from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';

const PricesPage = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/clinic"}><FaLongArrowAltLeft /></Link>Цены и услуги</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.head}>
                      Диагностика и терапевтические процедуры
                    </div>
                    <div className={Style.body}>
                      <table>
                          <thead>
                            <tr>
                              <th>Услуги</th>
                              <th>Цены</th>
                            </tr>
                          </thead>
                        <tbody>
                          <tr>
                            <td>Первичный прием</td>
                            <td>600&#8381;</td>
                          </tr>
                          <tr>
                            <td>Вторичный прием</td>
                            <td>350&#8381;</td>
                          </tr>
                          <tr>
                            <td>Вызов врача на дом</td>
                            <td>1000&#8381;</td>
                          </tr>
                          <tr>
                            <td>Дача препарата</td>
                            <td>50&#8381;</td>
                          </tr>
                          <tr>
                            <td>П/к, в/в инъекция</td>
                            <td>100&#8381;</td>
                          </tr>
                          <tr>
                            <td>П/к, в/в инъекция по назначению другой клиники</td>
                            <td>150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Постановка внутривенного катетера</td>
                            <td>350&#8381;</td>
                          </tr>
                          <tr>
                            <td>Снятие швов</td>
                            <td>150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Вскрытие абсцесса</td>
                            <td>350&#8381;</td>
                          </tr>
                          <tr>
                            <td>Фиксация агрессивного животного</td>
                            <td>300&#8381;</td>
                          </tr>
                          <tr>
                            <td>Гигиеническая чистка ушей</td>
                            <td>150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Анализ на отодектоз</td>
                            <td>300&#8381;</td>
                          </tr>
                          <tr>
                            <td>Стрижка когтей кошки</td>
                            <td> 150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Стрижка когтей мелкой собаки</td>
                            <td>200&#8381;</td>
                          </tr>
                          <tr>
                            <td>Стрижка когтей крупной собаки</td>
                            <td>300&#8381;</td>
                          </tr>
                          <tr>
                            <td>Снятие клеща</td>
                            <td>50&#8381;</td>
                          </tr>
                          <tr>
                            <td>Гигиеническая стрижка кошки</td>
                            <td>1500&#8381;</td>
                          </tr>
                          <tr>
                            <td>Гигиеническая стрижка собаки</td>
                            <td>2000&#8381; - 4000&#8381;</td>
                          </tr>
                          <tr>
                            <td>Чистка параанальных желез кошки</td>
                            <td> 150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Чистка параанальных желез мелкой собаки</td>
                            <td>200&#8381;</td>
                          </tr>
                          <tr>
                            <td>Чистка параанальных желез средней собаки</td>
                            <td>300&#8381;</td>
                          </tr>
                          <tr>
                            <td>Чистка параанальных желез крупной собаки</td>
                            <td>350&#8381;</td>
                          </tr>
                          <tr>
                            <td>Ультразвуковое удаление зубного камня</td>
                            <td>от 2000&#8381;</td>
                          </tr>
                          <tr>
                            <td>Удаление молочного зуба</td>
                            <td>350&#8381;</td>
                          </tr>
                          <tr>
                            <td>Удаление молочного клыка</td>
                            <td>450&#8381;</td>
                          </tr>
                          <tr>
                            <td>Удаление постоянного зуба</td>
                            <td>500&#8381;-1500&#8381;</td>
                          </tr>
                          <tr>
                            <td>Удаление постоянного клыка</td>
                            <td>700&#8381;-1500&#8381;</td>
                          </tr>
                          <tr>
                            <td>Санация раны 1 категории </td>
                            <td>150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Санация раны 2 категории</td>
                            <td>300&#8381;</td>
                          </tr>
                          <tr>
                            <td>Санация раны 3 категории (с наложением поверхностных швов)</td>
                            <td>1200&#8381;</td>
                          </tr>
                          <tr>
                            <td>Санация раны 4 категории (наложение внутренних и поверхностных швов)</td>
                            <td>2000&#8381;</td>
                          </tr>
                          <tr>
                            <td>Санация раны 5 категории (проникающие ранения, разрывы сухожилий и т.д.)</td>
                            <td>2700&#8381;</td>
                          </tr>
                          <tr>
                            <td>Взятие соскоба с кожи</td>
                            <td>50&#8381;</td>
                          </tr>
                          <tr>
                            <td>Люминесцентная диагностика</td>
                            <td>150&#8381;</td>
                          </tr>
                          <tr>
                            <td>Экспресс тест на панлейкопению кошек</td>
                            <td>1100&#8381;</td>
                          </tr>
                          <tr>
                            <td>Экспресс тест на парвовирусный энтерит собак</td>
                            <td>1100&#8381;</td>
                          </tr>
                          <tr className={Style.last}>
                            <td>Оксигенотерапия</td>
                            <td>1 час / 350&#8381;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={Style.head}>
                      Хирургия
                    </div>
                    <div className={Style.body}>
                      <table>
                        <thead>
                          <tr>
                            <th>Услуги</th>
                            <th>Цены</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Кастрация кота</td>
                            <td>1600&#8381;</td>
                          </tr>
                          <tr>
                            <td>Кастрация кота крипторха</td>
                            <td>2500&#8381;</td>
                          </tr>
                          <tr>
                            <td>Кастрация кобеля до 10 кг, в скобках цена за крипторха</td>
                            <td>4500&#8381; (5200&#8381;)</td>
                          </tr>
                          <tr>
                            <td>Кастрация кобеля до 20 кг, в скобках цена за крипторха</td>
                            <td>5500&#8381; (6200&#8381;)</td>
                          </tr>
                          <tr>
                            <td>Кастрация кобеля до 30 кг, в скобках цена за крипторха</td>
                            <td>6500&#8381; (7200&#8381;)</td>
                          </tr>
                          <tr>
                            <td>Кастрация кобеля до 40 кг, в скобках цена за крипторха</td>
                            <td>8000&#8381; (8700&#8381;)</td>
                          </tr>
                          <tr>
                            <td>Кастрация кобеля более 40 кг, в скобках цена за крипторха</td>
                            <td>10000&#8381; (11500&#8381;)</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия кошки</td>
                            <td>3000&#8381;</td>
                          </tr>
                          <tr>
                            <td>Овариогистерэктомия</td>
                            <td>3500&#8381;</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия суки до 10 кг</td>
                            <td>5500&#8381; (6200&#8381; с маткой)</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия суки до 20 кг</td>
                            <td>6500&#8381; (7200&#8381; с маткой)</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия суки до 30 кг</td>
                            <td>7500&#8381; (8500&#8381; с маткой)</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия суки до 40 кг</td>
                            <td>9000&#8381; (10500&#8381; с маткой)</td>
                          </tr>
                          <tr>
                            <td>Овариоэктомия суки до Более 40 кг</td>
                            <td>12000&#8381; (14000&#8381; с маткой)</td>
                          </tr>
                          <tr>
                            <td>Энуклеация глазного яблока</td>
                            <td>3500&#8381;</td>
                          </tr>
                          <tr className={Style.last}>
                            <td>Промывание носослезных каналов</td>
                            <td>2500&#8381;</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={Style.add}>* В стоимость не входят медикаменты и расходные материалы.</div>
                    </div>
                  <div className={Style.head}>Стационар</div>
                  <div className={Style.body}>
                    <table>
                      <thead>
                        <tr>
                          <th>Услуги</th>
                          <th>Цены</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Дневной стационар не вирусного животного (не более 10 часов) (кошка)</td>
                          <td>300&#8381;</td>
                        </tr>
                        <tr>
                          <td>Дневной стационар не вирусного животного (не более 10 часов) (собака)</td>
                          <td>600&#8381;</td>
                        </tr>
                        <tr>
                          <td>Круглосуточный стационар не вирусного животного (кошка)</td>
                          <td>500&#8381;</td>
                        </tr>
                        <tr>
                          <td>Круглосуточный стационар не вирусного животного (собака)</td>
                          <td>1000&#8381;</td>
                        </tr>
                        <tr className={Style.last}>
                          <td>Круглосуточный стационар вирусного животного от 7 дней пребывания</td>
                          <td>2000&#8381;</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={Style.add}>* В стоимость стационара входит аренда клетки, уборка, кормление, выгул по необходимости. Ветеринарные манипуляции и расходные материалы оплачиваются отдельно.</div>
                  </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default PricesPage; 
