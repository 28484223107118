import React from 'react';
import SectionStyle from '../styles/section.module.scss'
import Style from '../styles/footer.module.scss'
import UiLogo from './logo';
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const UiFooter = ({ location }) => {
    
    return (
        <footer id='footer'>
            <div className={SectionStyle.wrapper}>
                <div className={Style.content}>
                    <div className={Style.brand}>
                        <UiLogo 
                            location={location}
                        />  
                        <div className={Style.networks}>
                            <a className={Style.social} href='https://t.me/dailapu_ufa'>
                                <FaTelegram />
                            </a>  
                            <a className={Style.social} href='https://wa.me/79871380121'>
                                <FaWhatsapp />
                            </a>  
                            <a className={Style.social} href='tel:+79871380121'>
                                <FaPhone />
                            </a>
                        </div>
                    </div>
                    <nav className={Style.nav}>
                        <a onClick={() => window.dispatchEvent(new Event("openModal"))}>Контакты</a>
                        <Link to={"/hostel"}>Зоогостиница</Link>
                        <Link to={"/clinic"}>Ветклиника</Link>
                        <Link to={"/"}>Главная</Link>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default UiFooter;