import React from 'react';
import Style from '../styles/rules.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";
import util from '../../util';

const UiRules = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")}>
      <div className={SectionStyle.wrapper}>
        <h1 className={SectionStyle.title}>Условия содержания</h1>
        <div className={Style.content}>
          <div className={Style.item}>
              <div className={Style.text}>
                  <div className={Style.head}>Собаки <PiDog /></div>
                  <div className={Style.body}>Собаки содержатся в индивидуальных просторных вольерах. В каждом вольере теплый пол, лежанка, миски, игрушки. Трехразовый выгул. Имеется закрытая территория для выгула 10 соток для собак, склонных к побегам. Если собака приучена к поводку, то выгул за пределами территории гостиницы (леса, поля, озера). Для сильно стрессующих собак используем диффузоры с феромонами, успокоительные средства (необходима консультация специалиста).</div>
              </div>
          </div>
          <div className={Style.item}>
              <div className={Style.text}>
                  <div className={Style.head}>Кошки <PiCat /></div>
                  <div className={Style.body}>К кошкам применяется индивидуальный подход, ВСЕГДА. Это связано с тем, что кошки очень сильно подвержены стрессу, который может стать спусковым механизмом для развития патологий. У нас есть разные варианты содержания кошек: клетка; оборудованная комната с другими кошками; индивидуальное содержание в отдельной комнате. Почти всегда мы рекомендуем использовать успокоительные средства и феромоны (необходима консультация специалиста).</div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UiRules; 
