import React from 'react';
import Style from '../styles/second.module.scss'
import SectionStyle from '../styles/section.module.scss'
import { FaLongArrowAltLeft } from "react-icons/fa";
import util from '../../util';
import { Link } from 'react-router-dom';

const CostHostelPage = ({
  styles
}) => {

  return (
    <section className={[SectionStyle.section, styles.map(style => util[style]).join(" ")].join(" ")} id='about'>
      <div className={SectionStyle.wrapper}>
        <h1 className={Style.title}><Link className={Style.back} to={"/hostel"}><FaLongArrowAltLeft /></Link>Что нужно для заселения?</h1>
        <div className={Style.content}>
            <div className={Style.info}>
                    <div className={Style.head}>
                      Стоимость и правила проживания в зоогостинице. 
                    </div>
                    <div className={Style.body}>
                    ✅ Мы принимаем только вакцинированных и обработанных от паразитов животных. Владелец отдает ветеринарный паспорт животного (где есть подпись ветврача и печать клиники) на все время проживания питомца. Если животное не вакцинировано (или не имеет ветпаспорт), мы можем сами его вакцинировать в день поступления. Стоимость вакцины  2000 руб. Стоимость содержания собаки - от 1000 руб/сутки, кошки - от 500 руб/сутки (может увеличиться в зависимости от поведенческих проблем животного). Корм не входит в стоимость. Вы привозите все необходимое с собой. 
                    <br />
                    ✅ Если животному требуется лечение, мы предоставляем содержание в стационаре. Все животные с вирусными инфекциями принимаются исключительно в стационар. Стоимость стационара  - 2000 рублей в сутки. В стоимость входят манипуляции (инъекции, дача препаратов, обработка ран и швов). Медикаменты и инфузии оплачиваются отдельно. 
                    <br />
                    ✅ Мы не консультируем по поводу лечения вашего питомца. Мы строго следуем назначениям вашего лечащего врача. Если лечение не дает положительной динамики, ответственность несет ваш лечащий врач. 
                    <br />
                    ✅ Мы работаем исключительно по предоплате. В случае неоплаты проживания животного, мы вызываем службу отлова и отдаем животное в ПВС (пункт временного содержания). 
                    <br />
                    ✅ Если вы не можете вовремя забрать животное, свозить на прием к ветеринару или у вас нет возможности доставить его к нам, у нас есть услуга зоотакси за отдельную плату. 
                    <br />
                    ✅ Мы не занимаемся поиском хозяев для бездомных животных. Мы за ними временно ухаживаем, заботимся и любим, как своих, но их пристрой это ваша ответственность. 
                    <br />
                    ✅ Мы предоставляем скидки на содержание животных (скидки не распространяются на вакцины и медикаменты) волонтерам и благотворительным фондам, но только тем, которые зарекомендовали себя надежными и ответственными. 
                    <br />
                    ✅ Мы оставляем за собой право отказать в оказании наших услуг без объяснения причины.
                    </div>
                
            </div>
        </div>
      </div>
    </section>
  );
};

export default CostHostelPage; 
